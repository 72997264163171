export const theme = {
  colors: {
    primaryText: '#2e2d41',
    secondaryText: '#FFFFFF',
    primary: '#E89615',
    primaryDark: '#9ebc40',
    secondray: '#bb428b',
    linkHoverColor: '#d3d2d2',
    error: '#ff0000',
    primaryBgColor: '#f9f7f2',
    disabled: '#dddddd',
    success: '#28a745'
  },
  // font-size:
  fs: {
    xs: '1rem',
    base: '1.2rem',
    ss: '1.3rem',
    sm: '1.4rem',
    md: '1.6rem',
    ls: '1.7rem',
    lg: '1.8rem',
    h7: '2rem',
    h6: '2.2rem',
    h5: '2.4rem',
    h4: '2.6rem',
    h3: '2.8rem',
    h2: '3.2rem',
    h1: '5.2rem',
    startPopupDeskHead: '119px',
    startPopupDeskSubHead: '52px',
    startPopupMobBigHead: '62px',
    startPopupMobHead: '52px',
    startPopupMobSubHead: '16px',
    formSmall: '13.5px'
  },
  // font-weight
  fw: {
    thin: 100,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    maxBold: 900
  },
  breakpoints: {
    mobile: '340px',
    mobileXL: '576px',
    tab: '768px',
    desk: '992px',
    deskXL: '1200px'
  },
  fonts: {
    Playfair: 'Playfair Display, serif',
    Raleway: 'Raleway, sans-serif',
    Roboto: 'Roboto, sans-serif',
    Lobster: 'Lobster, cursive',
    Arial: 'Arial, Helvetica, sans-serif',
    Lato: 'Open-sans, sans-serif'
  },
  lato: 'Open-sans',
  raleway: 'Raleway, sans-serif',
  boxShadow: {
    primary: '0px 0px 8px 0px rgba(0, 0, 0, 0.3)',
    secondary: '0px 0px 5px 5px rgba(46, 45, 65, 0.2)',
    errorFocus: `0px 0 6px 0 rgba(243, 0, 74, 1)`,
    secondaryGreen: '0px 0px 7px 0px rgba(187, 206, 63, 1)'
  },
  shadow: {
    boxShadow: `0px 0 6px 0 rgba(0, 0, 0, 0.3)`
  },
  gradient: {
    green: 'linear-gradient(45deg,rgb(146,186,67) 10%,rgb(187,206,63) 100%)',
    orange: 'linear-gradient(45deg,#FFB13B 10%,#E89615 100%)',
    dark: 'linear-gradient(90deg,#110C23 0%,#2E2D45 100%)',
    blueLinear: 'linear-gradient(90deg, #33f5e0, #5172e8)',
    violet: 'linear-gradient(to right, #bb428b 0%, #8f3b81 100%)'
  },
  borderRadius: '8px',
  borderRadiusDesktop: '16px',
  loader: {
    bgColor: 'green',
    bgPadding: `40px`,
    spinCircleBorderColor: `blue`,
    spinCircleBorderRadius: `50%`,
    spinBorderColor: `#bbce3f`,
    spinWidth: `120px`,
    spinHeight: '120px',
    spinBorderWidth: `6px`,
    border: `6px`
  }
};
